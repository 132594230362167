import logo from './logo.svg';
import './App.css';

import {SMauiMap} from './components/map.js';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, deleteDoc, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//<script type="text/javascript" src="./components/Data/AllData.json"></script>

// import { AllData, MarkerIcons } from "./components/Data/AllData"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAD4RivikNk6J6ShYT-Xh__9GKU_LXLDT8",
  authDomain: "slowmaui-74710.firebaseapp.com",
  projectId: "slowmaui-74710",
  storageBucket: "slowmaui-74710.appspot.com",
  messagingSenderId: "1061934217587",
  appId: "1:1061934217587:web:11900de2a483fd59296d06",
  measurementId: "G-J23N84FYZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const FireDB = getFirestore(app)
//const storage = getStorage(app, "gs://orchestrate-7dfb7.appspot.com");


/*
if (true) {
  AllData.forEach((entry)=>{
    setDoc(doc(FireDB,"entries",":lat:"+entry.Lat+":lng:"+entry.Lng+":"),entry)
  })
//  const entry = AllData[0]
 // setDoc(doc(FireDB,"entries",":lat:"+entry.Lat+":lng:"+entry.Lng+":"),entry).then(()=>console.log("Good")).catch((e)=>console.log("Problem:",e))
}

if (false) {
  getDocs(collection(FireDB,"entries"))
  .then(docs => {
    docs.forEach((d)=>{console.log("deleting",d.id); deleteDoc(doc(FireDB,"entries",d.id))})
  })
}
*/

function App() {
  return (
    <div className="App">
      <SMauiMap fire={FireDB}/>
    </div>
  );
}

export default App;
