import React from 'react'
import {useState, useEffect, useRef} from 'react'
import { GoogleMap, LoadScript, OverlayView, Marker,} from '@react-google-maps/api';
import {MarkerF} from '@react-google-maps/api'
// import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import { collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";


import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const containerStyle = {width: '100vw',height: '100vh'};
const center = {lat: 20.8,lng: -156.345};

function tagsToUni(ts) {
  if (ts===null || ts===undefined) return "\u229A"
  if (ts.includes("Beach")) return "\uD83D\uDFE1" // "\u26F1" //"\uD83C\uDFD6" // "\uD83C\uDFF0" // "\uD83C\uDF34"  // "\uD83C\uDFD6" (umbrella)
  if (ts.includes("Trail")) return "\uD83C\uDF92" // "\uD83D\uDEB6"  // "\uD83E\uDD7E" (hiking book)
  if (ts.includes("Place")) return "\uD83D\uDCCC"
  if (ts.includes("Misc.")) return "\u262F"
  if (ts.includes("Puʻu"))  return "\u26F0"
  return "\u2688"
}

function textWidth(t,zoom) {
  const d = document.getElementById("textWidth")
  d.innerHTML = t
  var offset = d.clientWidth/1.25+10
  if (zoom <= 11) offset = offset + (zoom-4)
  return offset
}

function createSSControl(map) {
  const controlButton = document.createElement("button");

  // Set CSS for the control.
  controlButton.style.backgroundColor = "#fff";
  controlButton.style.border = "2px solid #fff";
  controlButton.style.borderRadius = "3px";
  controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
  controlButton.style.color = "rgb(25,25,25)";
  controlButton.style.cursor = "pointer";
  controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
  controlButton.style.fontSize = "25px";
  controlButton.style.lineHeight = "38px";
  controlButton.style.margin = "8px 8px 8px 8px";
  controlButton.style.padding = "0 5px";
  controlButton.style.textAlign = "center";
  controlButton.textContent = "\u23F5";
  controlButton.title = "Click to recenter the map";
  controlButton.type = "button";
  // Setup the click event listeners: simply set the map to Chicago.
  return controlButton;
}

function PicShow (props) {
  if (props.pics.length === 1) return (
    <div style={{width:"95%",height:"80%",position:"absolute",margin:"auto",top:0,bottom:0,left:0,right:0}}>
      <img  style={{height:"85vh", margin:"auto", left:0, right:0, display:"block", marginLeft:"auto", objectFit:"cover"}}src={props.pics[0].img} referrerPolicy="no-referrer"/>
    </div>
    )
  return ( //  <h2>{index}:{props.pics.length}</h2>
    <div style={{width:"95%",height:"80%",position:"absolute",margin:"auto",top:0,bottom:0,left:0,right:0}}>
      <Fade>
        {props.pics.map((fadeImage, index) => (
          <div key={index}  >
            <img  style={{height:"85vh", margin:"auto", left:0, right:0, display:"block", marginLeft:"auto", objectFit:"cover"}}src={fadeImage.img} referrerPolicy="no-referrer"/>

          </div>
        ))}
      </Fade>
    </div>
  )
}


function StoryTab(props) {
  console.log("SHowing url","https://slowmaui-74710.web.app/"+props.url)
  return (<iframe src={"https://slowmaui-74710.web.app/"+props.url} width="100%" height="100%"></iframe>)
}

function TheTabs(props) {
  const [value, setValue] = React.useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(()=>{
    if ("Page" in props.value) {setValue('1'); return}
    if ("Pics" in props.value && props.value.Pics.length > 0) {setValue('2'); return}
    if ("Vids" in props.value) {setValue('3'); return}   
  },[])

  if (value === '0') return (<Box>No Content for this location yet</Box>)

  return (
    <Box sx={{ width: '100%', height: '95vh', maxHeight: '95vh', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {"Page" in props.value && <Tab label="Description" value="1" />}
            {"Pics" in props.value && props.value.Pics.length > 0 && <Tab label="Pictures" value="2" />}
            {"Vids" in props.value && <Tab label="Videos" value="3" />}
          </TabList>
        </Box>
        {"Page" in props.value &&  <TabPanel value="1" sx={{p:0, m:0, height:"95%"}}><StoryTab url={props.value.Page.url}/></TabPanel>}
        {"Pics" in props.value && props.value.Pics.length > 0 &&  <TabPanel value="2"><PicShow sx={{p:0,m:0,height:"95vh"}}pics={props.value.Pics}/></TabPanel>}
        {"Vids" in props.value && <TabPanel value="3">Videos</TabPanel>}
      </TabContext>
    </Box>
  );
}

function CanvasShow(props) {
  const size = {width:"100vw",height:"100vh"}

  const transitionTime = 1.5*60  //
  const animationTime = 6*60 //

  const canvasRef = useRef(null)
  const tRef = useRef(null)
  const counter = useRef(null)
  const state = useRef(null)
  const ctx = useRef(null)
  const index = useRef(null)

  const img1 = useRef(null)
  const img2 = useRef(null)

  useEffect(() => {
      tRef.current = requestAnimationFrame(tick);
      state.current = "animate"
      index.current = {marker:Math.floor(Math.random() * props.markers.length),pic:-1}
   //   index.current = {marker:55,pic:-1}
      nextPic(img1)
      nextPic(img2)
      return (()=>cancelAnimationFrame(tRef.current))
      }, []);

  useEffect(()=>{canvasRef.current.width = window.innerWidth;
                 canvasRef.current.height = window.innerHeight;
                 ctx.current = canvasRef.current.getContext('2d'); 
                 ctx.current.font="8px Arial"
      },[canvasRef])

  const nextMarker = () => {
    index.current.marker = index.current.marker+1
    if (index.current.marker >= props.markers.length) index.current.marker = 0
  }

  const advance = () => {
    index.current.pic = index.current.pic + 1
    console.log("Advance to",index.current.marker,index.current.pic)
    if ("Pics" in props.markers[index.current.marker] 
        && index.current.pic < props.markers[index.current.marker].Pics.length
        && props.markers[index.current.marker].Pics.length > 0
        ) {
      console.log("SAME PLACE",index.current.pic)
      return
    }
    nextMarker()
    index.current.pic = 0
    while (!("Pics" in props.markers[index.current.marker]) || props.markers[index.current.marker].Pics.length === 0) 
       nextMarker()
    console.log("SLIDE #",index.current.marker,index.current.pic,"of",props.markers[index.current.marker].Pics.length)
  }

/*
    console.log("Advance with",index.current.marker,index.current.pic)
    if (!("Pics" in props.markers[index.current.marker])) {
      index.current.marker = index.current.marker + 1
      console.log("NO PICS HERE")
      index.current.pic = -1
      if (index.current.marker >= props.markers.length) index.current.marker = 0
      return advance()
    }
    else { // Pics available
      index.current.pic = index.current.pic+1
      if (index.current.pic >= props.markers[index.current.marker].Pics.length) {
          index.current.pic = -1
          index.current.marker = index.current.marker + 1
          if (index.current.marker >= props.markers.length) index.current.marker = 0
          return advance()
      }
      else return
    }
  }
  */

  // load the next image into img2; 
  const nextPic = (dest) => {
    advance()
    loadImg(dest)
  }

  const loadNext = () => {
    img1.current = img2.current
    nextPic(img2)
  }

  const loadImg = (dest) => {
    var img = new Image()
    img.referrerPolicy="no-referrer"
    img.src = props.markers[index.current.marker].Pics[index.current.pic].img
  //  console.log("Loading",img.src)
    img.onload = () => {dest.current = img}
    img.onerror = () => {console.log("ERROR"); nextPic(dest)}
  }

  const sizeImg = (image,grow) => {
      var scale = Math.min(window.innerWidth/image.current.width,window.innerHeight/image.current.height)
      var x = (window.innerWidth/2)-(image.current.width/2) * scale
      var y = (window.innerHeight/2)-(image.current.height/2)*scale
      var w = image.current.width * scale
      var h = image.current.height * scale

      // now adjust for animation

      if (grow) {
        var delta = (counter.current / animationTime) / 4     // % the way through the animation divided by 10; % of image to grow

        var w2 = w 
        var h2 = h

        w = w * (1+delta)
        h = h * (1+delta)

        x = x - ((w-w2)/2)
        y = y - ((h-h2)/2)
      }

      return({x:x,y:y,w:w,h:h})
  }

  const renderFrame = () => {

     switch (state.current) {
     case "next": console.log("next slide loaded"); counter.current=0; state.current="transition"; 
      break;
     case "animate":
 //     console.log("Animate")
      if (counter.current > animationTime) {counter.current=0; state.current="next";  console.log("load next slide")}
      if (img1.current) {
        ctx.current.globalAlpha = 1
  //      ctx.current.filter = "blur(20px)"
 //       ctx.current.drawImage(img1.current,0,0,window.innerWidth,window.innerHeight)
 //       ctx.current.filter = "blur(0px)"
        const xywh = sizeImg(img1,true)
        ctx.current.drawImage(img1.current,xywh.x,xywh.y,xywh.w,xywh.h)
      }
      break;
     case "transition":
 //     console.log("fade")
          document.getElementById("picCaption").innerHTML = props.markers[index.current.marker].Name
  
      if (counter.current > transitionTime) {counter.current=0; loadNext(); state.current="animate"; console.log("fade done; animating")}
      if (img1.current) {
        ctx.current.globalAlpha = 1-(counter.current/transitionTime)
        ctx.current.filter = "blur(20px)"
        ctx.current.drawImage(img1.current,0,0,window.innerWidth,window.innerHeight)
        ctx.current.filter = "blur(0px)"
        const xywh = sizeImg(img1,false)
        ctx.current.drawImage(img1.current,xywh.x,xywh.y,xywh.w,xywh.h)
       }
      if (img2.current) {
        ctx.current.globalAlpha = (counter.current/transitionTime)
        ctx.current.filter = "blur(20px)"
        ctx.current.drawImage(img2.current,0,0,window.innerWidth,window.innerHeight)
        ctx.current.filter = "blur(0px)"
        const xywh = sizeImg(img2,false)
        ctx.current.drawImage(img2.current,xywh.x,xywh.y,xywh.w,xywh.h)
       } else {console.log("IMAGE 2 NOT LOADED")}
      break;
     }
  };

  const tick = () => {
    if (!canvasRef.current) return;
    counter.current = counter.current+1
    if (counter.current > 300) {
      cancelAnimationFrame(tRef.current);
      return;
    }
    renderFrame();
    tRef.current = requestAnimationFrame(tick);
  };

  return (<>
           <canvas position="absolute" ref={canvasRef}/>
           <Box id="picCaption" sx={{bottom: 10, right: "50%", textAlign:"center", color:"white", position:"absolute"}}> </Box>
          </>
          )
}

function TheSlideShow(props) {
  const allpics = []
  props.markers.map((e)=>{
    if ("Pics" in e) {
      e.Pics.forEach((p)=>allpics.push({img:p.img}))
    }
  })
  console.log("Allpics is",allpics.length,allpics[0])
  return <CanvasShow markers={props.markers} sx={{height:"100vh",width:"100vw"}}/>
  // return <PicShow sx={{p:0, m:0, height:"95vh"}} pics={allpics}/>
}


function OverlayBox(props) {
  const {onClose, open, value } = props;
  const [phase,setPhase] = useState(null)

  var body = <TheSlideShow markers={value.markers}/>
  if (!value.ss) body = <TheTabs value={value.marker}/>

  return (
        <Dialog sx={{"& .MuiDialog-container": {"& .MuiPaper-root": {width: "98%", minWidth: "98%", height:"98%", minHeight: "98%", opacity:1},},}} 
              onClose={onClose} 
              open={open}
        >
        {body}
        <Button sx={{top: 0, right:0, position: "absolute"}} onClick={onClose}>{'\u24CD'}</Button>
      </Dialog>
  ) 
}

function SMmap(props) {

  const [overlay,setOverlay] = useState(false)
  const [selected,setSelected] = useState({}); const selectedRef = useRef();
  const [markers,setMarkers] = useState([]); const markersRef = useRef();
  const [zoom, setZoom] = useState(11)
  const [map,setMap] = useState(null)
 // const [canvas, setCanvas] = useState(false)

  useEffect(()=>{markersRef.current = markers},[markers])
  useEffect(()=>{selectedRef.current = selected},[selected])


  useEffect(()=>{
    var tmp = []
    getDocs(collection(props.fire,"entries")).then((ents)=>{
      Object.entries(ents.docs).forEach(([k,flow])=>{
            tmp.push(flow.data())
          })
      setMarkers(tmp)
    })
  },[])

  const gotClick = (marker) => {
      console.log("Got click",marker.Name,marker)
      if (!hasContent(marker)) return
      setSelected({ss:false,markers:markers,marker:marker})
      setOverlay(true)
  }

  const overlayClosed = () => {
     setOverlay(false)
  }

  const markerFormat = (entry) => {
    var op = 0;
    if (zoom>11) op = 0.2
    var nn = textWidth(markerLabel(entry).text,zoom)/8
    nn = nn - (nn/2) - 2
    return ({path: window.google.maps.SymbolPath.CIRCLE, scale:10, 
             strokeOpacity: 0, fillOpacity: 0,
            //strokeColor:"darkorange", strokeOpacity:1.0-op, strokeWeight:1, fillColor:"orange", fillOpacity:0.8,
            labelOrigin: new window.google.maps.Point(nn,0),
            anchor: new window.google.maps.Point(0,0) 
            })
  }

/*    if ("Icon" in entry) {
 //     return ({path:entry.Icon.path, scale:2*entry.Icon.scale, })
//    }
//    else {
      if ("Status" in entry && "visited" in entry.Status && entry.Status.visited) {
        if ("Page" in entry) {
          
           return ({path: window.google.maps.SymbolPath.CIRCLE, scale:8, strokeColor:"darkorange", strokeOpacity:1.0-op, strokeWeight:1, fillColor:"orange", fillOpacity:0.8,
                    labelOrigin: new window.google.maps.Point(nn,0),
                    anchor: new window.google.maps.Point(1,1) 
                  })
         }
        else
           return ({path: window.google.maps.SymbolPath.CIRCLE, scale:8, strokeColor:"darkblue", strokeOpacity:0.7-op, strokeWeight:1, fillColor:"darkblue", fillOpacity:0.5,
                    labelOrigin: new window.google.maps.Point(nn,0),
                    anchor: new window.google.maps.Point(1,1) 
                  })     }
      else
         return ({path: window.google.maps.SymbolPath.CIRCLE, scale:8, strokeColor:"grey", strokeOpacity:0.5-op, strokeWeight:1, fillColor:"grey", fillOpacity:0.3,
                  labelOrigin: new window.google.maps.Point(nn,0),
                  anchor: new window.google.maps.Point(1,1) 
                })
//    }
  } */

  const hasContent = (entry) => {
    if ("Pics" in entry && entry.Pics.length > 0) return true
    if ("Page" in entry) return true 
    if ("Vids" in entry && entry.Vids.length > 0) return true 
    return false
  }

  const markerLabel = (entry) => {
    const defm = "\u2688"
    const nn = tagsToUni(entry.Tags)+"\u0020"+entry.Name
    const label = {
          text:entry.Name,
          color:"rgb(80,80,80)",
          opacity: 1, 
          fontSize:"10pt", 
          display:"block", 
          marginLeft:0, 
          textAlign: "left", 
          align:"left", 
          padding:"0px"
    }
    if ("Page" in entry && zoom > 10) {label.text = nn; return (label)}
    if (zoom >= 12 && hasContent(entry)) {label.text = nn; return label}
    if (zoom >=12 && "Status" in entry && "visited" in entry.Status && entry.Status.visited) {label.text = defm+"\u0020"+entry.Name; label.color = "rgb(130,130,130)"; return (label)}
    if (zoom >= 13) {label.text = defm+"\u0020"+entry.Name; label.color = "rgb(150,150,150)"; return (label)}
 //   if (zoom > 12) {label.color = "rgb(175,175,175)"; return (label)}
    label.text = defm
    label.color = "rgb(180,180,180)"
    return(label)
  }

  const gotLoad = (m) => {
    setMap(m)
    m.setOptions({styles:[//{"featureType": "landscape","elementType": "labels","stylers": [{"visibility": "off"}]},  // https://developers.google.com/maps/documentation/javascript/style-reference
                 //{featureType: "poi", elementType: "labels", stylers: [{"visibility":"off"}]},
                 //{featureType: "transit", stylers: [{visibility:"off"}]},
                 {"featureType": "all", "elementType": "labels", "stylers": [{"visibility":"off"}]},
                 {featureType: "water", elementType: "geometry.fill",stylers: [{ color: "#7070ff" }]},
                ]})
    m.setOptions({streetViewControl:false})
    m.setOptions({mapTypeControlOptions:{style:window.google.maps.MapTypeControlStyle.DROPDOWN_MENU}})
    m.setOptions({fullscreenControl:false})
    m.setOptions({scaleControlOptions:{position:window.google.maps.ControlPosition.TOP_LEFT}})
    const cdiv = document.createElement("div")
    const ssc = createSSControl(m)
    cdiv.appendChild(ssc)
    ssc.addEventListener("click", () => {
      const m = "marker" in selectedRef.current ? selectedRef.current.marker : markersRef.current[0]
 //     console.log("Slideshow",m,selected,markersRef.current[0])
      setSelected({ss:true,markers:markersRef.current,marker:m})
      setOverlay(true)
 //     setCanvas(true)
    });
    m.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(cdiv)
  }

  const gotZoomChange = () => {
    if (map != null) {
      console.log("Zoom is",map.getZoom())
       setZoom(map.getZoom())
       if (map.getZoom() <= 11) {
        console.log("LABELS ON")
          map.setOptions({styles:[{"featureType": "all", "elementType": "labels", "stylers": [{"visibility":"on"}]}]})
       }
       else {
          console.log("LABELS OFF")
          map.setOptions({styles:[{"featureType": "all", "elementType": "labels", "stylers": [{"visibility":"off"}]}]})
       }
    }
  }

  //const closeCanvas = () => {setCanvas(false)}

  return (
    <>
    <LoadScript googleMapsApiKey="AIzaSyBwAPeHbLkcxAU3Z4KKdcK5Jpsm57NqQKo">
      <GoogleMap
        onLoad={gotLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onZoomChanged={gotZoomChange}
        streetViewControl={false}
        rotateControl={false}
      >
      <>
      {markers.map((m)=>(
        <Marker key={m.Lat+"."+m.Lng} 
                 position={{lat:m.Lat,lng:m.Lng}} 
                 onClick={()=>gotClick(m)}
                 icon={markerFormat(m)}
                 title={m.Name}
         //        label={{text:"hi",color:"red"}}
                 label={markerLabel(m)}
        />
      ))}
      </>
      </GoogleMap>
    </LoadScript>
    

    {overlay && <OverlayBox 
           onClose={overlayClosed}
           open={overlay}
           value={selected}
    />} 

    <div id="textWidth" style={{position:"absolute",width:"auto",visibility:"hidden"}}></div>

    </>
  )
}

const SMauiMap = React.memo(SMmap)

export {SMauiMap}


/*
        <MarkerF
          position={{lat:20.81,lng:-156.3}}
          onClick={() => {
            gotClick();
          }}
        />
*/

            /*    icon={{path:props.icons.Fish}}  */